import React from "react";
import SEO from "../components/seo";
import FourOhFour from "../components/fourOhFour";
import { keywords } from "../content/keywords";
const NotFound = () => {
    return (<>
      <SEO title={"404"} keywords={keywords}/>
      <FourOhFour />
    </>);
};
export default NotFound;
