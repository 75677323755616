import React from "react";
import { Link } from "gatsby";
import classes from "./fourOhFour.module.scss";
const FourOhFour = () => {
    return (<div className={classes.fourOhFourRoot}>
      <h1>Hmmm... that doesn't seem to be here.</h1>
      <Link to={"/"}>
        <p>Back to safety</p>
      </Link>
    </div>);
};
export default FourOhFour;
